<template>
  <nav class="ciam-tabs" :class="[kind]" aria-label="Tabs">
    <ciam-button
      v-for="tab in tabs"
      :key="tab.name"
      class="ciam-tabs-item"
      :to="tab.route"
      :class="{
        current: current === tab,
        inactive: current !== tab && !tab.disabled,
        disabled: tab.disabled,
      }"
      :data-e2e="'tab-' + tab.id"
      :aria-current="tab === current ? 'page' : undefined"
      @click.prevent="onTabClick(tab)"
    >
      <ciam-icon v-if="tab.icon" :name="tab.icon" aria-hidden="true" class="ciam-tabs-item-icon"></ciam-icon>
      <span>{{ tab.name }}</span>
    </ciam-button>
  </nav>
</template>

<script>
import { is } from 'ramda';
import CiamIcon from '@/components/CiamIcon';
import CiamButton from '@/components/CiamButton';

export default {
  name: 'ciam-tabs',
  props: {
    kind: {
      type: String,
      default: 'tab',
      validator: (style) => ['tab', 'navigation'].includes(style),
    },
    tabs: {
      type: Array,
      /*
      {
      id: '',
      name: '',
      icon: '', // optional
      route, // optional
      default: bool, // optional
      disabled: bool // optional
       */
      validator: (tabs) => tabs.every((tab) => is(String, tab.name) && is(String, tab.id)),
    },
  },
  components: {
    CiamIcon,
    CiamButton,
  },
  data() {
    return {
      current: null,
    };
  },
  methods: {
    onTabClick(tab) {
      this.$emit('tab-change', tab);
    },
    refreshCurrentTab(currentRoute) {
      // Strategy 1 - exact match
      const { default: defaultCurrent, current } = this.tabs.reduce(
        (m, tab) => {
          if (tab.route && tab.route.name === currentRoute.name) {
            m.current = tab;
          }

          if (tab.default) {
            m.default = tab;
          }
          return m;
        },
        { default: null, current: null }
      );

      // there must be always a selected tab at a time
      // if we did not find a tab matching the route, then fallback on the default tab
      this.current = current || defaultCurrent;
    },
  },
  updated() {
    this.refreshCurrentTab(this.$route);
  },
  mounted() {
    this.unsubscribeRouterListener = this.$router.afterEach((to /*, from */) => {
      if (to) {
        this.refreshCurrentTab(to);
      }
    });

    this.refreshCurrentTab(this.$route);
  },
  destroyed() {
    this.unsubscribeRouterListener();
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
.ciam-tabs {
  &.navigation {
    @apply hidden sm:ml-6 sm:flex sm:space-x-8;

    .ciam-tabs-item {
      @apply text-gray-900 inline-flex items-center px-1 pt-1 text-sm font-medium border-b-2 border-transparent;

      &.inactive {
        @apply text-gray-500 hover:border-gray-300 hover:text-gray-700;
      }

      &.disabled {
        @apply text-gray-400 cursor-default;
      }

      &.current {
        @apply border-indigo-500 text-gray-900 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium;
      }

      .ciam-tabs-item-icon {
        @apply -ml-0.5 mr-2;
      }
    }
  }

  &.tab {
    @apply block -mb-px flex space-x-8 border-b border-gray-200;

    .ciam-tabs-item {
      @apply whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm border-transparent group inline-flex items-center;

      &.inactive {
        @apply text-gray-500 hover:text-gray-700 hover:border-gray-300;
      }

      &.disabled {
        @apply text-gray-400 cursor-default;
      }

      &.current {
        @apply border-indigo-500 text-indigo-600;
      }

      .ciam-tabs-item-icon {
        @apply -ml-0.5 mr-2;
      }
    }
  }
}
</style>
