import { problemAwareHttp, http } from '@/http';
import { pathOr, sortBy } from 'ramda';
import { pipe } from 'rxjs';
import CommonService from '@/pages/commonService';
import i18n from '@/i18n';

const extractAndSortOrganizations = pipe(
  // first extrat organizations from response (if any)
  pathOr([], 'data._embedded.organizations'.split('.')),
  // then sort them with a stable key
  sortBy((organization) => organization.name + organization.id)
);

const extractAndSortMembers = pipe(
  // first extrat organizations from response (if any)
  pathOr([], 'data._embedded.organizationMembers'.split('.'))
);

export default class OrganizationService {
  /**
   * @typedef {object} Organization
   * @property {String} id
   * @property {String} name
   * @property {String} createdAt
   * @property {String} updatedAt
   */

  /**
   * @return {Promise<Organization[]>}
   */
  static getAll() {
    return http
      .get('/organizations', {
        params: {},
      })
      .then(extractAndSortOrganizations);
  }

  static async getSecurityPolicy(organizationId) {
    return problemAwareHttp.get(`/organizations/${organizationId}/security-policy`).then((response) => response.data);
  }

  static async getContactsEmails(organizationId) {
    return problemAwareHttp.get(`/organizations/${organizationId}/contacts/emails`).then((response) => response.data);
  }

  static async updateSecurityPolicy(organizationId, mfaEnforced) {
    await problemAwareHttp.put(`organizations/${organizationId}/security-policy`, {organizationId, mfaEnforced});
  }

  static async updateContactsEmails(organizationId, emails) {
    const response = await problemAwareHttp.put(`/organizations/${organizationId}/contacts/emails`, emails);

    if (!response) {
      return Promise.reject();
    }

    return Promise.resolve();
  }

  static async update(organizationId, organization) {
    const response = await problemAwareHttp.put(`/organizations/${organizationId}`, organization);

    if (!response) {
      return Promise.reject();
    }

    return Promise.resolve();
  }

  static async get(id) {
    return await CommonService.get("organizations", id);
  }

  /**
   * @typedef {object} OrganizationMember
   * @property {string} lastName
   * @property {string} firstName
   * @property {string} id
   * @property {string} invitedAt
   * @property {string} role
   * @property {string} email
   */

  /**
   *
   * @param {string} id - organization uid
   * @return {Promise<OrganizationMember[]>}
   */
  static getMembers(id) {
    return problemAwareHttp.get(`/organizations/${id}/members`).then(extractAndSortMembers);
  }

  static inviteMember(organizationId, email, role) {
    return problemAwareHttp.post(`/organizations/${organizationId}/members/invite`, {
      email,
      role,
    });
  }
  static revokeMember(organizationId, userId) {
    return problemAwareHttp.delete(`/organizations/${organizationId}/members/${userId}`);
  }

  static createServiceAccount(organizationId, role) {
    return problemAwareHttp.post(`/organizations/${organizationId}/members/service-account`, {
      role,
    });
  }

  static async createOrganizationWithName(userId, organizationName) {
    const response = await problemAwareHttp.post(
      `/organizations/`,
      { name: organizationName, userId },
      {
        // organization creation on mutualized take more than 30seconds,
        // we increase the default timeout here for this request
        timeout: http.defaults.timeout + 60_000, // add 1 minute to the default timeout
      }
    )
    return response
  }

  /**
   * Get the dedicated billing details.
   * @param {string} organizationId the organization id
   */
  static getBillingDetailsUrl(organizationId) {
    return problemAwareHttp
      .get(`/organizations/${organizationId}/billing`, { params: { referer: location.href } })
      .then((response) => response.data.url);
  }

  /**
   * Create a conversation with the organization members.
   * @param {string} organizationId the organization id
   * @param {string} subject the conversation subject
   */
  static contactOrganization(organizationId, subject) {
    return problemAwareHttp
      .post(`/organizations/${organizationId}/initiate-conversation`, { subject })
      .then((response) => response.headers.location);
  }

  /**
   *
   * @param {string} organizationId the organization id
   * @returns {Promise<boolean>} true if the organization users can trigger cloud-iam on-call team, false otherwise.
   * @description this method never fails with a rejected promise and will always yield a boolean.
   */
  static canTriggerOnCall(organizationId) {
    return problemAwareHttp
      .get(`/organizations/${organizationId}/options`)
      .then((response) => response.data.canTriggerOnCall || false)
      .catch((_err) => false); // silent-fail, we will leverage server-side monitoring (e.g. sentry)
  }

  /**
   *
   * @param {string} organizationId the organization id
   * @returns {Promise<boolean>} true if the organization can view dedicated status page, false otherwise.
   * @description this method never fails with a rejected promise and will always yield a boolean.
   */
  static canAccessDedicatedStatusPage(organizationId) {
    return problemAwareHttp
      .get(`/organizations/${organizationId}/options`)
      .then((response) => response.data.canAccessDedicatedStatusPage || false)
      .catch((_err) => false); // silent-fail, we will leverage server-side monitoring (e.g. sentry)
  }

  /**
   * Report an incident to the on-call team.
   * @param {string} organizationId the organization id
   * @param {string} description the incident description
   */
  static reportIncident(organizationId, description) {
    return problemAwareHttp.post(`/organizations/${organizationId}/incidents`, { description });
  }
}
